<template lang="pug">
	.main-wrapper.lotes-listar
		header.main-wrapper-header
			.p-grid.p-align-center
				.p-col-12.ta-right
					h1.text-header.text-secondary Medclub / <b>Lotes</b>

		Dialog.dialogApagar(header='Remover lotes' :modal='true' :visible.sync='dialogApagar')
			.ta-center
				p Confirma remover o lote <b>cód. {{ dialogApagar_data.id }}</b>?
				.mt-4.my-2
					ProgressSpinner.waitingRemover(v-if='waitingRemover' strokeWidth='4')
					Button.p-button-danger(v-else label='Remover' icon='jam jam-trash' @click='removerlote(dialogApagar_data.id)')

		Dialog.dialogApagar(header='Gerar relatório' :modal='true' :visible.sync='dialogImprimir')
			.ta-center
				p Selecione o formato de exportação:
				.mt-4.my-2
					ProgressSpinner.waitingRemover(v-if='waitingRelatorio' strokeWidth='4')
					div(v-else)
						Button.p-button.mr-3(label='CSV' icon='jam jam-document' @click='gerarRelatorio(dialogImprimir_data.id,"csv")')
						Button.p-button.mr-3(label='EXCEL' icon='jam jam-document' @click='gerarRelatorio(dialogImprimir_data.id,"xlsx")')
						Button.p-button(label='PDF' icon='jam jam-printer' @click='gerarRelatorio(dialogImprimir_data.id,"pdf")')

		Panel.mb-2(v-show='!waiting' header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576')
			.p-grid.p-fluid.p-align-end

				.p-col-12.p-md-4
					label.form-label Competência:
					.p-inputgroup
						Calendar.filter-data( v-model='filters.dt_competencia' dateFormat="mm/yy" :locale="ptbr" :yearRange="`2020:${proximoAno}`" :yearNavigator='true'
							:manualInput='false' :touchUI='windowInnerWidth < 576' @input='applyFilters()' view='month' )
						Button(label='' v-tooltip.top="'Limpar Filtros'" icon="jam jam-close-rectangle" @click="removeFilters()")
		.ta-right.mt-4.mb-2(v-show='!waiting')

		ProgressBar(v-if='waiting' mode="indeterminate")
		div(v-else-if='list.length == 0')
			p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
		div(v-else)
			Panel.datatable(header='Lista de Lotes')
				Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
				DataTable(:value="list" @sort="onSort($event)" :sortField="order.field" :sortOrder="order.sortOrder")
					Column(headerStyle='width: 7%;' bodyStyle='text-align: center;' field='id' header='Cód.' sortable)
					Column(headerStyle='width: 20%;' field='cd_estabelecimento__nm_fantasia' header='Empresa')
						template(#body='props')
							span(v-if='props.data.ds_pagamento') {{ props.data.ds_pagamento }}<br/>
							span <b>{{ props.data.cd_estabelecimento.nm_fantasia }}</b>
					Column(headerStyle='width: 10%;' bodyStyle='text-align: center;' field='dt_competencia' header='Competência')
						template(#body='props')
							span {{ props.data.dt_competencia_f }}<br />
					Column(headerStyle='width: 15%;' bodyStyle='text-align: center;' field='qtd_guia' header='Qtd Guias')
					Column(headerStyle='width: 10%;' bodyStyle='text-align: center;' field='nr_valorbruto' header='Valor bruto' sortable)
						template(#body='props')
							span {{ props.data.nr_valorbruto_f }}<br />
					Column(headerStyle='width: 10%;' bodyStyle='text-align: center;' field='ie_status_pagamento' header='Situação' sortable)
						template(#body='props')
							span(v-if='props.data.ie_status_pagamento == "E"') Gerado
							span(v-if='props.data.ie_status_pagamento == "A"') Processando
							span(v-if='props.data.ie_status_pagamento == "P"') Pago
					Column(headerStyle='width: 18%;' bodyStyle='text-align: center;' header='Ações')
						template(#body='props')
							.ta-center
								Button.ml-1.p-button-raised.p-button-rounded(
									v-show='props.data.ie_status_pagamento == "E"'
									v-tooltip.top="'Editar'"
									icon="jam jam-write"
									@click="$router.push(`/lotes/listar-guias/${ props.data.id }/`)"
									)
								span.ml-1
									Button.p-button-raised.p-button-rounded(
										v-tooltip.top="'Gerar relatório'"
										icon="jam jam-printer"
										@click='dialogImprimir_data = props.data; dialogImprimir = true'
									)
								Button.ml-1.p-button-raised.p-button-rounded.p-button-danger(
									v-tooltip.top="'Remover'"
									v-if="props.data.ie_status_pagamento == 'E'"
									icon="jam jam-trash"
									@click="dialogApagar_data = props.data; dialogApagar = true"
									)
				Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

</template>

<style lang="scss">
	.lotes-listar {
		.waitingRelatorio, .waitingRemover {
			width: 28px;
			height: auto;
		}
	}
</style>

<script>
	import ProgressBar from 'primevue/progressbar'
	import ProgressSpinner from 'primevue/progressspinner'
	import Panel from 'primevue/panel'
	import DataTable from 'primevue/datatable'
	import Column from 'primevue/column'
	import Paginator from 'primevue/paginator'
	import Button from 'primevue/button'
	import Tooltip from 'primevue/tooltip'
	import Dropdown from 'primevue/dropdown'
	import Calendar from 'primevue/calendar'
	import Dialog from 'primevue/dialog'

	import { Lotes } from './../../middleware'
	import { Relatorio } from './../../middleware'
	import moment from 'moment'
    import { pCalendarLocale_ptbr } from './../../utils'
	import wsConfigs from './../../middleware/configs'
	import { saveAs } from 'file-saver'
	export default {
		created () {
            this.applyFilters()
		},
		components: { ProgressBar, Panel, DataTable, Column, Paginator, Button, Tooltip,
			ProgressSpinner, Dropdown, Calendar, Dialog },
		directives: { tooltip: Tooltip },
		data () {
			return {
				list: [],
                ptbr: pCalendarLocale_ptbr,
				windowInnerWidth: window.innerWidth,
				waiting: false,
				waitingRelatorio: 0,
				waitingRemover: false,
				dialogApagar_data: {},
				dialogApagar: false,
				dialogImprimir: false,
				dialogImprimir_data: {},
				proximoAno: parseInt(moment().format("YYYY"))+1,
				filters: {
					cd_estabelecimento: null
				},
				options: {
					estabelecimentos: []
				},
				paginator: {
					page: this.$route.query.pg || 1,
					per_page: wsConfigs.paginator_perPage,
					count: 0
				},
				order: {
					field: 'id',
					sortOrder: 1
				}
			}
		},
		methods: {
			formatPrice (val) {
				return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)
			},
			getList (params) {
				this.waiting = true
				return Lotes.findAll(params).then(response => {
					if (response.status == 200) {
						this.paginator.count = response.data.count
						response.data.results.forEach(pagamento => {
							pagamento.nr_valorbruto_f = this.formatPrice(pagamento.nr_valorbruto)
							if (pagamento.dt_competencia) pagamento.dt_competencia_f = moment(pagamento.dt_competencia,'MM/YYYY').format('MM/YYYY')
						})
						this.list = response.data.results
					}
					this.waiting = false
					return true
				})
			},
			applyFilters (page) {
				this.paginator.page = page || 1
				let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }
				if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
				Object.keys(this.filters).forEach((key) => {
					if (this.filters[key]) {
						if (key === 'dt_competencia') {
							params[key] = moment(this.filters[key]).format('MM/YYYY')
						} else params[key] = this.filters[key]
					}
				})
				params.order = `${this.order.sortOrder === 1 ? '' : '-'}${this.order.field}`
				this.getList(params)
			},
			onPage (ev) {
				this.applyFilters(ev.page + 1)
			},
			onSort(ev) {
				this.order.field = ev.sortField
				this.order.sortOrder = ev.sortOrder
				this.applyFilters()
			},
			removeFilters () {
				this.filters.dt_competencia = null
				let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }
				this.getList(params)
			},
			gerarRelatorio (id, formato) {
				this.waitingRelatorio = true
				Relatorio.getRelatorioPagamento(id, formato).then(response => {
					this.waitingRelatorio = 0
					if (response.status == 200) {
						if (response.data.detail) {
							this.$toast.error(response.data.detail, { duration: 3000 })
						} else {
							let blob
							if (formato == 'csv'){
								blob = new Blob([response.data], { type: 'text/csv; charset=utf-8' })
								saveAs(blob, `relatorio-protocolo-guia-${ id }.csv`)
							} else if (formato === 'xlsx') {
                                blob = response.data
                                saveAs(blob, `relatorio-protocolo-guia-${ id }.xlsx`)
                            } else {
								blob = new Blob([response.data], { type: 'application/pdf; charset=utf-8' })
								saveAs(blob, `relatorio-protocolo-guia-${ id }.pdf`)
							}
						}
					}
				})
			},
			removerlote (id) {
				this.waitingRemover = true
				Lotes.remove(id).then(response => {
					this.waitingRemover = false
					if (([204]).includes(response.status)) {
						this.$toast.info('Lote removido com sucesso', { duration: 3000 })
						this.dialogApagar = false
						this.applyFilters()
					} else if (response.data.detail) {
						if (typeof response.data.detail == 'string')
							this.$toast.error(response.data.detail, { duration: 3000 })
						else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
					}
				})
			}
		}
	}
</script>
